<template>
  <div>
    <el-row :gutter="10"
            v-if="queryInfo">
      <el-col>
        <span>考点名：</span>{{tableData.knowledge_desc}}
      </el-col>
    </el-row>
    <el-table border
              stripe
              highlight-current-row
              :max-height="$store.state.tableHeight"
              :data="tableData.user_data.list"
              style="width: 100%">
      <el-table-column prop="knowledge_id"
                       align="center"
                       width="160"
                       label="考点ID" />
      <el-table-column prop="knowledge_desc"
                       align="center"
                       label="考点名称" />
      <el-table-column prop="count"
                       align="center"
                       label="学生做题次数" />
      <el-table-column prop="score"
                       align="center"
                       label="学生总得分率" />
      <el-table-column prop="name"
                       align="left"
                       label="操作">

        <template slot-scope="{row}">
          <div class="text_btn_primary"
               @click="toDetail(row)">考点详细数据</div>

        </template>
      </el-table-column>
    </el-table>

  </div>
</template>

<script>

export default {

  data () {
    return {
      tableData: {
        knowledge_desc: '',
        user_data: {
          list: []
        },
      },
      user_data: [],
      queryInfo: null
    }
  },
  mounted () {

    this.initData()
  },
  methods: {
    initData () {
      // console.log(decodeURIComponent(this.$route.query.queryInfo))
      this.queryInfo = JSON.parse(decodeURIComponent(this.$route.query.queryInfo))

      this.$http({
        url: '/api/v1/combination/weak_list',
        method: 'get',
        params: this.queryInfo
      }).then(res => {
        console.log(res);
        this.tableData.knowledge_desc = this.queryInfo.knowledge_desc
        this.tableData.user_data.list = res.data.list
      })
    },
  // 查看详情
  toDetail (row) {
      let form = {
        knowledge_id: row.knowledge_id,
      }
      form = Object.assign(JSON.parse(JSON.stringify(this.queryInfo)), form)
      this.$router.push('/studentCondition/weakness/detail?form=' + JSON.stringify(form))
    },

  }
}
</script>

<style lang="scss" scoped>
.el-col {
  font-size: 16px;
  padding: 10px;
  span {
    font-weight: bold;
  }
}
</style>
